gsap.registerPlugin(TextPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const nav = document.querySelector('.nav');
const logo = document.querySelector('.logo');
const logos = document.querySelectorAll('.logo');
const navItemContainerMobile = document.querySelector(
	'.nav__item__container--right[data-type="mobile"]'
);
const navLinksMobile = document.querySelectorAll(
	'.nav__item__container--right[data-type="mobile"] .nav__link'
);
const navLinksDesktop = document.querySelectorAll(
	'.nav__item__container--right[data-type="desktop"] .nav__link'
);
const darkModeBtns = document.querySelectorAll('.dark-mode-button');
const hamburgerBtn = document.querySelector('#hamburger--open');
const hamburgerOpen = document.querySelector('#hamburger--open');
const hamburgerClose = document.querySelector('#hamburger--close');
const cards = document.querySelectorAll('.card');
const sectionBgTexts = document.querySelectorAll('.section__background-text');
const resumeLink = document.querySelector('#resume');
const body = document.querySelector('body');
const aboutSection = document.querySelector('.about');
const aboutMain = document.querySelector('.about__main');
const aboutTextContainer = document.querySelector('.about__text--container');
let theme = localStorage.getItem('theme');
const heroBottomDivs = document.querySelectorAll('.hero__bottom div');
const scrollWheel = document.querySelector('#scroll-wheel');

const mm = gsap.matchMedia();
// Ito yung pag walang conditions object basic matchMedia Syntax
// gsap.matchMedia().add('(min-width: 801px)', () => {gsap.to, basta Tween});

mm.add(
	{
		// These are conditions object
		// isMobile: '(max-width: 800px)',
		// isDesktop: '(min-width: 801px)',
		isMobile: '(max-width: 999px)',
		isDesktop: '(min-width: 1000px)',
		isDesktopLrg: '(min-width: 1200px)',
	},
	(context) => {
		// Docs https://greensock.com/docs/v3/GSAP/gsap.matchMedia()
		// This functon is called whenever any of these conditions are true
		// We will save the context in a variable, to access the 'conditions' within the function
		// Conditions will return true or false
		// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.

		// console.log(context.conditions);
		const { isMobile, isDesktop, isDesktopLrg } = context.conditions;
		const skillStart = isMobile ? 'top 70%' : 'top-=10% 80%';
		const heroImgStart = isMobile ? 'top-=90% 0%' : 'top-=30% 0%';
		let sectionBackgroundTextY = isMobile ? '-50%' : '-70%';
		let sectionBackgroundTextDuration = isMobile ? 3 : 2;
		const footerTlStart = () => {
			if (isMobile) {
				return 'top 50%';
			} else if (isDesktop) {
				return 'top 80%';
			} else if (isDesktopLrg) {
				return 'top 80%';
			}
		};
		const pinElementOnDesktop = isMobile ? false : true;
		// function mouseFn() {
		// 	const mouseTl = gsap.timeline({
		// 		defaults: {
		// 			duration: 3,
		// 		},
		// 		scrollTrigger: {
		// 			trigger: '.hero__bottom',
		// 			scrub: 2,
		// 			start: isMobile ? 'top 50%' : 'top 75%',
		// 			end: 'bottom 30%',
		// 			markers: true,
		// 		},
		// 	});
		// 	mouseTl
		// 		.fromTo(
		// 			'.hero__bottom--text',
		// 			{
		// 				autoAlpha: 1,
		// 				y: '0%',
		// 			},
		// 			{
		// 				autoAlpha: 0,
		// 				y: '-100%',
		// 				duration: isMobile ? 2 : 1,
		// 			}
		// 		)
		// 		.fromTo(
		// 			'.hero__bottom--mouse-container',
		// 			{
		// 				autoAlpha: 1,
		// 				y: '0%',
		// 			},
		// 			{
		// 				autoAlpha: 0,
		// 				y: '-100%',
		// 			},
		// 			'<'
		// 		);

		// 	// ScrollTrigger.create({
		// 	// 	trigger: '.hero__bottom',
		// 	// 	animation: mouseTl,
		// 	// 	scrub: 2,
		// 	// 	start: isMobile ? 'top 50%' : 'top 75%',
		// 	// 	end: 'bottom 30%',
		// 	// 	markers: true,
		// 	// });
		// }
		// Hero timeline
		const tlHero = gsap.timeline({
			defaults: {
				ease: 'ease',
				duration: 1,
			},
			onComplete: () => {
				const mouseTl = gsap.timeline({
					defaults: {
						duration: 3,
					},
					scrollTrigger: {
						trigger: '.hero__bottom',
						scrub: 2,
						start: isMobile ? 'top 50%' : 'top 75%',
						end: 'bottom 30%',
						// markers: true,
					},
				});
				mouseTl
					.fromTo(
						'.hero__bottom--text',
						{
							autoAlpha: 1,
							y: '0%',
						},
						{
							autoAlpha: 0,
							y: '-100%',
							duration: isMobile ? 2 : 1,
						}
					)
					.fromTo(
						'.hero__bottom--mouse-container',
						{
							autoAlpha: 1,
							y: '0%',
						},
						{
							autoAlpha: 0,
							y: '-100%',
						},
						'<'
					);

				// ScrollTrigger.create({
				// 	trigger: '.hero__bottom',
				// 	animation: mouseTl,
				// 	scrub: 2,
				// 	start: isMobile ? 'top 50%' : 'top 75%',
				// 	end: 'bottom 30%',
				// 	markers: true,
				// });
			},
		});

		tlHero
			.fromTo(
				'.section__hero .shape-1',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 5,
				},
				'<'
			)
			.fromTo(
				'.section__hero .shape-2',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 5,
				},
				'<'
			)
			.fromTo(
				'.section__hero .shape-3',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 5,
				},
				'<'
			)
			.fromTo(
				'.hero__greet',
				{
					autoAlpha: 0,
					y: '-10%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					ease: 'power4',
					duration: 2,
					delay: 0.25,
				},
				'<'
			)
			.fromTo(
				'.hero__title',
				{
					autoAlpha: 0,
					y: '-10%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					ease: 'power4',
					duration: 2,
					delay: 0.25,
				},
				'<'
			)
			.fromTo(
				'.hero__subtitle',
				{
					autoAlpha: 0,
					y: '-100%',
				},
				{
					autoAlpha: 1,
					y: '0%',
				},
				'<'
			)
			.fromTo(
				'.hero__img-box',
				{
					clipPath: 'polygon(0 0, 0 0, 0 0, 0 0);',
					autoAlpha: 0,
				},
				{
					clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0)',
					ease: 'expo.out',
					autoAlpha: 1,
					duration: 2,
				},
				'<.1'
			)
			.fromTo(
				'.hero__img',
				{
					autoAlpha: 0,
					// y: '-5%',
					y: '13%',
				},
				{
					autoAlpha: 1,
					// y: '6%',
					y: '6%',
					ease: 'power4.out',
				},
				'<.3'
			)
			.fromTo(
				// Hire me
				'.hero__left--lower a[data-title="hire-me"]',
				{
					autoAlpha: 0,
					y: '-100%',
				},
				{
					autoAlpha: 1,
					y: '0%',
				},
				'<.5'
			)
			.fromTo(
				'.hero .socials',
				{
					autoAlpha: 0,
					clipPath: 'polygon(0 0, 0 0, 0 0, 0 0);',
				},
				{
					autoAlpha: 1,
					clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0)',
					ease: 'expo.out',
				},
				'<.3'
			)
			.fromTo(
				'.hero .socials a',
				{
					autoAlpha: 0,
					y: '-100%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					stagger: 0.3,
				},
				'<.1'
			)
			.fromTo(
				nav,
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					ease: 'expo.out',
				},
				'<.3'
			)
			.fromTo(
				[logo, navLinksDesktop],
				{
					autoAlpha: 0,
					y: '-100%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					duration: 1,
					stagger: 0.2,
					ease: 'ease',
					duration: 1,
				},
				'<.1'
			)
			.fromTo(
				[darkModeBtns, hamburgerBtn],
				{
					autoAlpha: 0,
					y: '-100%',
					rotate: '45deg',
				},
				{
					autoAlpha: 1,
					y: '0%',
					rotate: '0deg',
					stagger: 0.1,
				},
				'<'
			)
			.fromTo(
				heroBottomDivs,
				{
					autoAlpha: 0,
					y: '-30%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					stagger: 0.3,
				},
				'<'
			);

		// About timeline
		const intro = () => {
			const tl = gsap.timeline({
				defaults: {
					duration: 3,
					ease: 'expo.inOut',
				},
			});

			gsap.set('.greeting span', { display: 'inline-block' });

			tl.fromTo(
				'.about .circle',
				{ y: '-140%', autoAlpha: 0 },
				{
					y: '0%',
					autoAlpha: 1,
					scale: 2,
					background:
						'radial-gradient(circle, hsla(0, 0%, 0%, 1) 50%, hsla(250, 46%, 49%, 1) 100%)',
				}
			)
				.fromTo(
					'.greeting span:first-of-type',
					{ autoAlpha: 0, x: '-100%' },
					{ autoAlpha: 1, x: '0%' },
					'<1'
				)
				.fromTo(
					'.greeting .name__1st',
					{ autoAlpha: 0, x: '-50%' },
					{ autoAlpha: 1, x: '0%' },
					'<1'
				)
				.fromTo(
					'.greeting .name__2nd',
					{ autoAlpha: 0, x: '-30%' },
					{ autoAlpha: 1, x: '0%' },
					'<1'
				)
				.to('.greeting--container', { scale: 4.3, autoAlpha: 0, display: 'none' })
				.to(
					'.about .circle',
					{
						scale: 2,
						background:
							'radial-gradient(circle, hsla(0, 0%, 0%, 1) 100%, hsla(250, 46%, 49%, 1) 100%)',
					},
					'<1'
				);

			return tl;
		};

		const aboutMainTl = () => {
			const tl = gsap.timeline({
				defaults: {
					duration: 3,
					ease: 'expo.inOut',
				},
			});

			tl.fromTo(
				'.about__img',
				{ y: '50%', autoAlpha: 0 },
				{
					y: '0%',
					autoAlpha: 1,
					scrollTrigger: {
						// Kaya sa .about__main ko to nilagay para mas mababa yung ending kasi pag sa about__img lang, mas mataas yung ending nya at start. Kasi naka base sya sa position ng element sa DOM
						trigger: '.about__main',
						start: 'top center',
						scrub: true,
					},
				},
				'+=.3'
			).fromTo(
				'.about__text--container',
				{
					y: () => {
						if (isMobile) {
							return '10%';
						} else {
							return '50%';
						}
					},
					autoAlpha: 0,
				},
				{
					y: '0%',
					autoAlpha: 1,
					scrollTrigger: {
						trigger: '.about__text--container',
						scrub: true,
						id: 'text-container',
					},
				}
			);

			const highlights = [...document.querySelectorAll('.highlight')];

			highlights.forEach((el) => {
				ScrollTrigger.create({
					trigger: el,
					start: isDesktopLrg ? 'top+=600px center' : 'top+=800px center',
					end: isDesktopLrg ? 'bottom+=600px top' : 'bottom+=800px top',
					onEnter: () => {
						el.classList.add('active');
					},
				});
			});
			return tl;
		};

		// Kaya ko pinasok sa isang fn at pinaghiwalay yung timeline
		// Kasi dalawa yung ScrollTrigger at naka link lang sya sa isang animation or timeline
		const introTl = gsap.timeline();
		const mainTl = gsap.timeline();
		introTl.add(intro());
		mainTl.add(aboutMainTl());

		ScrollTrigger.create({
			trigger: '.about__intro',
			scrub: true,
			pin: true,
			animation: introTl,
			// pinSpacing: false,
			id: 'intro',
			end: '+=1000px',
			onEnter: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				aboutSection.style.backgroundColor = '#000';
				body.style.backgroundColor = '#000';
				nav.style.display = 'none';
				aboutTextContainer.style.color =
					theme === 'light' ? 'hsl(60, 4%, 79%)' : 'var(--clr-text-400)';
			},
			onLeave: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				nav.style.display = 'flex';
				aboutTextContainer.style.color =
					theme === 'light' ? 'hsl(60, 4%, 79%)' : 'var(--clr-text-400)';
			},
			onLeaveBack: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				aboutSection.style.backgroundColor = 'var(--clr-body-background)';
				body.style.backgroundColor = 'var(--clr-body-background)';
				nav.style.display = 'flex';
				aboutTextContainer.style.color =
					theme === 'light' ? 'hsl(60, 4%, 79%)' : 'var(--clr-text-400)';
			},
			onEnterBack: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				aboutSection.style.backgroundColor = '#000';
				body.style.backgroundColor = '#000';
				nav.style.display = 'none';
				aboutTextContainer.style.color =
					theme === 'light' ? 'hsl(60, 4%, 79%)' : 'var(--clr-text-400)';
			},
		});

		ScrollTrigger.create({
			trigger: '.about__main',
			scrub: true,
			end: () => {
				if (isMobile) {
					return '+80% top';
				} else {
					return '+80% top';
				}
			},
			pin: pinElementOnDesktop,
			animation: mainTl,

			onEnter: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				aboutSection.style.backgroundColor = '#000';
				body.style.backgroundColor = '#000';
				aboutMain.style.backgroundColor = '#000';
				nav.style.display = 'none';
				aboutTextContainer.style.color =
					theme === 'light' ? 'hsl(60, 4%, 79%)' : 'var(--clr-text-400)';
			},
			onLeave: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				aboutSection.style.backgroundColor = 'var(--clr-body-background)';
				body.style.backgroundColor = 'var(--clr-body-background)';
				aboutMain.style.backgroundColor = 'var(--clr-body-background)';
				nav.style.display = 'flex';
				aboutTextContainer.style.color =
					theme === 'light' ? 'var(--clr-text-200)' : 'var(--clr-text-400)';
			},
			onLeaveBack: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				nav.style.display = 'flex';
				aboutTextContainer.style.color =
					theme === 'light' ? 'hsl(60, 4%, 79%)' : 'var(--clr-text-400)';
			},
			onEnterBack: () => {
				// Always get the theme
				theme = localStorage.getItem('theme');
				aboutSection.style.backgroundColor = '#000';
				body.style.backgroundColor = '#000';
				aboutMain.style.backgroundColor = '#000';
				nav.style.display = 'none';
				aboutTextContainer.style.color =
					theme === 'light' ? 'hsl(60, 4%, 79%)' : 'var(--clr-text-400)';
			},
		});

		// Projects timeline
		const projectsTl = gsap.timeline({
			defaults: {
				duration: 0.7,
				ease: 'expo.inOut',
			},
			scrollTrigger: {
				trigger: '.projects',
				start: isMobile ? 'top 70%' : 'top 90%',
				id: 'projectsTl',
				toggleActions: 'play none none reverse',
			},
		});

		projectsTl
			.fromTo(
				'.projects .section__title--normal',
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
				},
				'<'
			)
			.fromTo(
				'#resume',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
				},
				'-=.5'
			)
			.fromTo(
				'.projects .section__background-text',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
				},
				'<'
			)
			.fromTo(
				cards,
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					duration: 1,
					stagger: 0.3,
				},
				'-=.5'
			)
			.fromTo(
				'.projects .skill',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					stagger: {
						each: 0.2,
						from: 'random',
						grid: [3, 6],
					},
					delay: 0.6,
				},
				'<'
			)
			.fromTo(
				'.projects .shape-4',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 1,
				},
				'<'
			);

		// More projects timeline
		const moreProjectsTl = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'expo.inOut',
			},
			scrollTrigger: {
				trigger: '.section__title--sm',
				endTrigger: '.more-projects',
				start: isMobile ? 'top 80%' : 'top 90%',
				id: 'moreProjectsTl',
				toggleActions: 'play none none reverse',
			},
		});

		moreProjectsTl
			.fromTo(
				'.projects .shape-5',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 1,
				},
				'<'
			)
			.fromTo(
				'.section__title--sm',
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
				},
				'<'
			)
			.fromTo(
				'.card--transparent',
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					duration: 1,
				},
				'<.8'
			)
			.fromTo(
				'.more-projects ul li',
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					duration: 1,
					stagger: 0.3,
					ease: Elastic.easeOut.config(1, 0.3),
				}
			);

		// Skills and certificate section timeline and animations
		const skillsTl = gsap.timeline({
			defaults: {
				duration: 1,
			},
			scrollTrigger: {
				trigger: '.skills',
				id: 'skillsSection',
				start: skillStart,
				toggleActions: 'play none none reverse',
			},
		});

		skillsTl
			.fromTo(
				'.skills .shape-6',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 1,
				}
			)
			.fromTo(
				'.skills .skills__container .section__title--normal',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
				},
				'<1'
			)
			.fromTo(
				'.skills .section__background-text',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
				},
				'<.7'
			);

		const skillsPillsAndCertificatesTl = gsap.timeline({
			defaults: {
				duration: 1,
			},
			scrollTrigger: {
				trigger: '.skills',
				id: 'skillPillsSection',
				start: skillStart,
				toggleActions: 'play none none reverse',
			},
		});

		skillsPillsAndCertificatesTl
			.fromTo(
				'.skills .skill',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					stagger: {
						each: 0.2,
						from: 'random',
						grid: [3, 6],
					},
					delay: 0.6,
				}
			)
			.fromTo(
				'.certificates__container .section__title--normal',
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					ease: 'expo.inOut',
				},
				'-=.8'
			)
			.fromTo(
				'.certificates .certificates__main li',
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					stagger: 0.2,
					ease: Elastic.easeOut.config(1, 0.3),
				},
				'-=.6'
			)
			.fromTo(
				'.certificates .certificates__other__title',
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					stagger: 0.2,
					ease: 'expo.inOut',
				},
				'-=.7'
			)
			.fromTo(
				'.certificates .certificates__other li',
				{
					autoAlpha: 0,
					y: '20%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					stagger: 0.2,
					ease: Elastic.easeOut.config(1, 0.3),
				},
				'-=.6'
			);

		// Footer timeline and animations
		const footerTl = gsap.timeline({
			defaults: {
				duration: 1,
				ease: 'expo.inOut',
			},
			scrollTrigger: {
				trigger: '.certificates__other',
				endTrigger: '.footer',
				start: footerTlStart,
				toggleActions: 'play none none reverse',
			},
		});

		footerTl
			.fromTo(
				'.footer .link',
				{
					y: '-100%',
					autoAlpha: 0,
				},
				{
					y: '0%',
					autoAlpha: 1,
					stagger: 0.2,
				}
			)
			.fromTo(
				'.footer__logo .logo',
				{
					y: '-100%',
					autoAlpha: 0,
				},
				{
					y: '0%',
					autoAlpha: 1,
				},
				'-=.6'
			)
			.fromTo(
				'.footer .socials',
				{
					autoAlpha: 0,
					clipPath: 'polygon(0 0, 0 0, 0 0, 0 0);',
				},
				{
					autoAlpha: 1,
					clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0)',
					ease: 'expo.out',
				},
				'<.3'
			)
			.fromTo(
				'.footer .socials a',
				{
					autoAlpha: 0,
					y: '-100%',
				},
				{
					autoAlpha: 1,
					y: '0%',
					stagger: 0.3,
				},
				'<.1'
			);

		// ************ Other elements to animate
		// Hero img animate when scroll
		gsap.fromTo(
			'.hero__img',
			{
				y: '6%',
			},
			{
				y: '-10%',
				scrollTrigger: {
					trigger: '.hero__img',
					start: heroImgStart,
					scrub: 2,
				},
			}
		);

		// ScrollTrigger.create({
		// 	trigger: '.hero__bottom--text',
		// 	start: 'top center',
		// 	markers: true,
		// });

		// gsap.to('.hero__bottom--text', {
		// 	y: '-100%',
		// 	duration: 5,
		// 	scrollTrigger: {
		// 		trigger: '.hero__bottom--text',
		// 		markers: true,
		// 		start: 'top 80%',
		// 		end: 'bottom 80%',
		// 		scrub: 2,
		// 	},
		// });

		// gsap.to('.hero__bottom--mouse-container', {
		// 	y: '-100%',
		// 	duration: 0.5,
		// 	scrollTrigger: {
		// 		trigger: '.hero__bottom--mouse-container',
		// 		markers: true,
		// 		start: 'top 80%',
		// 		scrub: 2,
		// 	},
		// });

		gsap.to(scrollWheel, {
			y: 5,
			yoyo: true,
			repeat: -1,
			duration: 1,
			ease: 'ease',
		});

		// Hamburger and Menu animations
		// *****************************
		const navMobileAnimationTl = gsap.timeline({
			defaults: {
				ease: 'expo.inOut',
				duration: 1,
			},
		});

		hamburgerOpen.addEventListener('click', () => {
			if (navMobileAnimationTl.reversed()) {
				navMobileAnimationTl.play();
			} else {
				navMobileAnimationTl
					.fromTo(
						navItemContainerMobile,
						{
							autoAlpha: 0,
							y: '-100%',
							scale: 0,
						},
						{
							autoAlpha: 1,
							y: '0%',
							scale: 1,
							transformOrigin: 'center',
							duration: 1,
						}
					)
					.fromTo(
						'.close-icon',
						{
							autoAlpha: 0,
							rotate: '90deg',
							y: '-100%',
						},
						{
							autoAlpha: 1,
							y: '0%',
							rotate: '0deg',
							duration: 1,
						},
						'=-.5'
					)
					.fromTo(
						navLinksMobile,
						{
							y: '-100%',
							autoAlpha: 0,
						},
						{
							y: '0%',
							autoAlpha: 1,
							stagger: 0.2,
						},
						'=-.5'
					);
			}
		});

		hamburgerClose.addEventListener('click', () => {
			navMobileAnimationTl.reverse();
		});
		// *****************************

		// Resume Link follow the scroll
		ScrollTrigger.create({
			trigger: '.projects',
			pin: resumeLink,
			start: 'top 20%',
			end: 'bottom-=10% top',
			scrub: true,
		});

		// For Adding border to resume button
		gsap.fromTo(
			resumeLink,
			{
				border: '1px solid hsla(196, 100%, 48%, 0)',
				boxShadow: '0 0 10px 2px hsla(196, 100%, 48%, 0)',
			},
			{
				border: '1px solid hsla(196, 100%, 48%, 1)',
				boxShadow: '0 0 10px 2px hsla(196, 100%, 48%, .2)',
				duration: 2,
				scrollTrigger: {
					trigger: '.projects',
					start: 'top 20%',
					scrub: true,
				},
			}
		);

		// Section background texts
		sectionBgTexts.forEach((el) => {
			gsap.to(el, {
				y: sectionBackgroundTextY,
				duration: sectionBackgroundTextDuration,
				scrollTrigger: {
					trigger: el,
					scrub: 3,
					start: 'top 50%',
					end: 'bottom+=100% top',
				},
			});
		});

		// Shapes Scroll Trigger
		gsap.fromTo(
			'.section__hero .shape-1',
			{
				y: '0%',
			},
			{
				y: '-20%',
				scrollTrigger: {
					trigger: '.section__hero .shape-1',
					start: 'top 50%',
					scrub: 2,
				},
			}
		);

		gsap.fromTo(
			'.section__hero .shape-2',
			{
				y: '0%',
			},
			{
				y: '-50%',
				rotate: 5,
				scrollTrigger: {
					trigger: '.section__hero .shape-2',
					start: '10% top',
					scrub: 2,
				},
			}
		);

		gsap.fromTo(
			'.section__hero .shape-3',
			{
				y: '0%',
			},
			{
				y: '-30%',
				scrollTrigger: {
					trigger: '.section__hero .shape-3',
					start: 'top 30%',
					scrub: 2,
				},
			}
		);

		gsap.fromTo(
			'.projects .shape-4',
			{
				y: '0%',
			},
			{
				y: '-60%',
				scale: 1.2,
				rotate: 360,
				scrollTrigger: {
					trigger: '.projects',
					start: 'top 30%',
					scrub: 3,
				},
			}
		);

		gsap.fromTo(
			'.projects .shape-5',
			{
				y: '0%',
			},
			{
				y: '-100%',
				rotate: 360,
				scrollTrigger: {
					trigger: '.projects',
					start: 'top 30%',
					scrub: 3,
					id: 'shape5',
				},
			}
		);

		gsap.fromTo(
			'.skills .shape-6',
			{
				y: '0%',
			},
			{
				y: '-50%',
				scrollTrigger: {
					trigger: '.skills',
					start: 'top 30%',
					scrub: 1,
				},
			}
		);

		// ScrollTo
		const aboutBtns = [...document.querySelectorAll('.aboutBtn')];
		const projectBtns = [...document.querySelectorAll('.projectsBtn')];

		aboutBtns.forEach((btn) => {
			btn.addEventListener('click', () => {
				gsap.to(window, { duration: 1, scrollTo: { y: '#aboutSection', offsetY: '-500' } });
			});
		});

		projectBtns.forEach((btn) => {
			btn.addEventListener('click', () => {
				gsap.to(window, {
					duration: 1,
					scrollTo: { y: '#projectsSection', offsetY: '80' },
				});
			});
		});

		logos.forEach((btn) => {
			btn.addEventListener('click', () => {
				gsap.to(window, { duration: 1, scrollTo: { y: 0 } });
			});
		});
	}
);
